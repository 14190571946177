<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

const { t } = useT();

const changeSlideDisabled = ref(false);
const sliderRef = ref();
const sliderActiveSlide = ref(0);
const isLast = ref(false);

const { playersActivity, fetchedPlayersActivity } = useLatestWinners();
const documentVisibleState = useDocumentVisibility();

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto" },
	created(slider) {
		sliderActiveSlide.value = slider.track.details?.abs || 0;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details?.abs || 0;
	},
	detailsChanged(slider) {
		isLast.value = slider.track.details?.slides?.[playersActivity.value.length - 1]?.portion >= 0.9;
	}
};

const updateAfterAnimation = debounce(() => {
	sliderRef.value?.slider?.update();
	changeSlideDisabled.value = false;
}, 900);

const updateSlider = () => {
	changeSlideDisabled.value = true;
	updateAfterAnimation();
};

watch(
	() => playersActivity,
	() => updateSlider(),
	{ deep: true }
);
</script>

<template>
	<div v-if="playersActivity.length" class="wrapper" :class="{ 'disable-drag': changeSlideDisabled }">
		<div class="header">
			<AText type="h8 h5-md" class="header-title" data-tid="last-winners">{{ t("recent winners") }}</AText>
		</div>

		<ASlider v-if="documentVisibleState === 'hidden'" ref="sliderRef" :options="sliderOptions">
			<transition-group :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
				<div v-for="(item, index) in fetchedPlayersActivity" :key="item.id" class="card-wrapper keen-slider__slide">
					<div class="card">
						<MRecentGameCard class="card-item" :data-tid="`last-winners-card-${index}`" :item="item" />
					</div>
				</div>
			</transition-group>
		</ASlider>
		<ASlider v-if="documentVisibleState === 'visible'" ref="sliderRef" :options="sliderOptions">
			<transition-group :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
				<div v-for="(item, index) in playersActivity" :key="item.id" class="card-wrapper keen-slider__slide">
					<div class="card">
						<MRecentGameCard class="card-item" :data-tid="`last-winners-card-${index}`" :item="item" />
					</div>
				</div>
			</transition-group>
		</ASlider>
		<div class="nav-container">
			<div @click="!changeSlideDisabled && sliderRef.slider?.prev()">
				<NuxtIcon
					class="swiper-button"
					:class="{ 'swiper-button-disabled': sliderActiveSlide === 0 }"
					name="16/right-slider"
				/>
			</div>
			<div @click="!changeSlideDisabled && !isLast && sliderRef.slider?.next()">
				<NuxtIcon class="swiper-button" :class="{ 'swiper-button-disabled': isLast }" name="16/left-slider" />
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.disable-drag {
	pointer-events: none;
}

.wrapper {
}

.flip-list-move {
	transition: transform 0.8s ease;
}

.list-transform-move {
	transition: transform 0.8s;
}

.list-width-enter-active,
.list-width-leave-active {
	transition:
		min-width 0.8s,
		width 0.8s,
		opacity 0.8s;
}
.list-width-enter-from,
.list-width-leave-to {
	opacity: 0;
	width: 0 !important;
	min-width: 0 !important;
	max-width: 0 !important;
}

.card-wrapper {
	min-width: 198px;
	max-width: 198px;
	width: 198px !important;
	&:first-child {
		margin-left: -4px;
	}
	&:last-child {
		margin-right: -4px;
	}
}

.card {
	padding: 0 4px;
	width: 100%;
}

.card-item {
	width: 100%;
}

.header {
	z-index: 20;
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.nav-container {
	display: flex;
	gap: 24px;
	margin-top: 16px;
	@include media-breakpoint-down(md) {
		display: none;
	}
	.swiper-button {
		color: var(--primary);
		cursor: pointer;

		&-disabled {
			opacity: 0.4;
		}
	}
}

.cards {
	$self: &;
	margin-top: gutter(6);
	position: relative;
	z-index: 5;

	@include media-breakpoint-down(md) {
		width: calc(100% + 15px);
		margin-top: gutter(5);
	}
	&-container {
		display: flex;
		position: relative;
		padding-top: gutter(2.5);
	}
}
</style>
